import * as React from 'react'

import Layout, { Block, LeadingMotto } from '../components/Layout'
import Circles from '../images/circles.svg'
import Seo from '../components/seo'

const FirmPage = () => (
    <Layout>
        <Seo title='Phystech Ventures' />
        <Block
            color='bgGray'
            className='pt-28 md:pt-48 pb-10 2xl:pt-72 2xl:pb-20'
            nestedClassName='p-7'
        >
            <LeadingMotto
                icon={<Circles />}
                text={
                    <div className='w-auto lg:w-4/6'>
                        Empowering deeptech innovators for a better tomorrow
                    </div>
                }
            />
        </Block>
        <Block
            color='white'
            className='pt-10 pb-20'
            nestedClassName='p-7'
        >
            <div className='text-2xl font-bold font-serif leading-header mt-2 pb-5'>
                About Phystech Ventures
            </div>
            <div className='pb-6 2xl:pb-10 max-w-7xl mx-auto'>
                <div className='mt-10 people-md pt-0 leading-header w-full xl:w-1/2'>
                    <p className='text-grey'>Welcome to Phystech Ventures, a leading deeptech venture capital fund dedicated to fueling the next generation of scientific breakthroughs. We specialize in investing in Deeptech startups that leverage fundamental scientific advancements to create disruptive solutions and transform industries.</p>
                    <p className='text-grey'>At Phystech Ventures, we understand the unique challenges and opportunities inherent in Deeptech investments. We are passionate about supporting entrepreneurs who possess deep technical expertise and are pushing the boundaries of scientific knowledge to develop groundbreaking technologies.</p>
                    <p className='text-grey'>Our investment strategy revolves around identifying visionary founders and exceptional teams who are tackling complex problems with the power of deep technology. From next-gen computing and biotechnology to advanced materials and energy innovation, we seek out startups that have the potential to reshape industries and make a lasting impact on society.</p>
                    <p className='text-grey'>At Phystech Ventures, we believe in the power of strategic partnerships and collaboration. We work closely with our portfolio companies, providing not only capital but also deep industry knowledge, mentorship, and access to our extensive network of industry partners, corporate leaders, and potential customers. Our goal is to help our portfolio companies scale rapidly, navigate regulatory landscapes, and achieve market leadership.</p>
                    <p className='text-grey'>As a part of Phystech Ventures, you'll gain more than just financial backing. You'll join a community of like-minded innovators, researchers, and industry experts who share your passion for pushing the boundaries of science and technology. Together, we can unlock the full potential of deeptech and drive meaningful change in the world.</p>
                    <p className='text-grey'>If you're a Deeptech startup with a groundbreaking idea and a passion for scientific advancement, we invite you to explore the possibilities with Phystech Ventures. Let's shape the future of technology together.</p>
                </div>
            </div>
        </Block>
    </Layout>
)

export default FirmPage
